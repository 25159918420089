import { Grid, InputLabel, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

export default function ProgramSetup({ value, setValue }: any) {

  return (
    <div>
      <Typography variant="subtitle1" gutterBottom>
        We need some basic information on your program before we can analyse the data.
        Make sure your Program name is unique.
      </Typography>
      < br />
      <Typography variant="subtitle2" gutterBottom>
        Program Name
      </Typography>

      <TextField id="outlined-basic" fullWidth variant="outlined"
        value={value.name}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setValue({ ...value, name: event.target.value });
        }} />
      <br />
      <br />
      <Typography variant="subtitle2" gutterBottom>
        Program Description
      </Typography>
      <TextField id="outlined-basic" fullWidth variant="outlined"
        value={value.description}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setValue({ ...value, description: event.target.value });
        }} />
      <br />
      <br />
      <Grid container spacing={4}>
        <Grid sm={12} item>
          <InputLabel id="duration-mode-select-label">Program start date</InputLabel>
            <DatePicker
            sx={{ width: "100%" } } 
              value={dayjs(value.startDateUtc)}
              onChange={(startDate) => {
                setValue({ ...value, startDateUtc: startDate?.toISOString() });
              }}
            />
        </Grid>
      </Grid>
      <br/>
      <br/>
    </div>
  );
}