import { read, utils } from "xlsx";
import { useState } from "react";
import { MappingAnalysisResponse, Survey } from './types';
import QuestionDataBuilder from './builder/formBuilder';
import TypeMapper from './builder/typeMapper';
import FathomClient from 'api/fathomapi';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { LinearProgress } from '@mui/material';

export default function SpreadSheetParse({ onSubmit }: any) {
    const { getAccessTokenSilently } = useAuth0();
    const [sheetData, setSheetData] = useState([] as any[]);
    const [analysedSurvey, setAnalysedSurvey] = useState({} as MappingAnalysisResponse);
    const [headers, setHeaders] = useState([] as string[]);
    const [parsedSurvery, setParsedSurvey] = useState({} as Survey);
    const [modalOpen, setModalOpen] = useState(false);
    const [analysing, setAnalysing] = useState(false);
    const { programId } = useParams();

    const cleanString = (str: string) => {
        return str.replaceAll("â","").replaceAll(/’/g, "").replaceAll(/'/g, "").replaceAll(/[^\x20-\x7E]/g, '').trim();
    }

    const getHeaderRow = (sheet:any, range:any) => {
        const headers = [];
        let C;
        const R = range.s.r;
        for (C = range.s.c; C <= range.e.c; ++C) {
            /* walk every column in the range */
            const cell = sheet[utils.encode_cell({ c: C, r: R })];
            /* find the cell in the first row */
            let hdr = "EMPTY HEADER " + C; // <-- replace with your desired default
            if(cell && cell.t == "s") {
                cell.v = cleanString(cell.v);
                if(cell.w) {
                    cell.w = cleanString(cell.w);
                }
            }
            if (cell && cell.t) {
                hdr = utils.format_cell(cell)
            }
            headers.push(cleanString(hdr));
        }
        return headers;
    }

    const parseData = (resultData: Survey) => {
        resultData.structure.forEach(questionBlock => {
            var questions = questionBlock.questions;
            questions.forEach(question => {
                question.responses = [];
            })
        });

        sheetData.forEach((i:any) => {
            resultData.structure.forEach(questionBlock => {
                var questions = questionBlock.questions;
                questions.forEach(question => {
                    let title = cleanString(question.title);
                    let val = i[title];
                    if(val == undefined){
                        let noSpaceTitle = title.replaceAll(" ", "");
                        console.log(noSpaceTitle);
                        let filtered = Object.keys(i).filter(z => {
                            console.log(cleanString(z).replaceAll(" ", ""));
                            return cleanString(z).replaceAll(" ", "") == noSpaceTitle;
                        });
                        console.log(filtered);
                        if(filtered.length > 0){
                            val = i[filtered[0]]
                        }else{
                            console.log(title);
                        }
                    }
                    question.responses.push(String(val))
                })
            })
        });

        resultData.responseCount = sheetData.length;
        setParsedSurvey(resultData);
        setModalOpen(true);
    }

    const runQuestionAnalysis = async (headers: string[], sheetData: any[]) => {
        let qha = [];
        for (let index = 0; index < headers.length; index++) {
            const hd = headers[index];
            qha.push({
                question: {
                    id: "",
                    header : hd
                },
                answers: sheetData.map(z => ""+z[hd])
            })
        }
        setAnalysing(true);

        const apiClient = new FathomClient(await getAccessTokenSilently());
        const response = await apiClient.post(
            `{clientId}/programs/` + programId + '/reports/analyseQuestions', qha).catch((e) => {
                setAnalysing(false);
            });
        let data = response?.data;
        if(data != null){
            setAnalysedSurvey(data);
            setAnalysing(false);
        }else {
            setAnalysing(false);
        }
    }

    const manageFile = async (e:any) => {
        /* e is a File */
        const data = await e.arrayBuffer();
        /* data is an ArrayBuffer */
        const workbook = read(data);
        var sheet = workbook.Sheets[Object.keys(workbook.Sheets)[0]];

        let firstCellVal = sheet["A1"];
        let rStart = 0, rEnd = 1;
        if((firstCellVal?.h || firstCellVal?.v).indexOf("there are more sheets in this document") > -1){
            rStart = 2, rEnd = 3;
        }
        var range = utils.decode_range(sheet['!ref'] as string);
        range.s.r = rStart;
        var header = getHeaderRow(sheet,range);
        range.s.r = rEnd;

        sheet['!ref'] = utils.encode_range(range);

        var aoa = utils.sheet_to_json(sheet, { header: header, range: range, defval: ""});

        let aoa1 = [] as any[];

        aoa.forEach((row :any) => {
            let newRow = {} as any;
            Object.keys(row as any).forEach(key => {
                let newKey = cleanString(key);
                if(!newKey.startsWith('EMPTY HEADER ')){
                    newRow[newKey] = row[key];
                }
            })
            aoa1.push(newRow);
        })
        header = header.filter(z => !z.startsWith('EMPTY HEADER '));
        setSheetData(aoa1);
        setHeaders(header);
        runQuestionAnalysis(header, aoa);
    }

    const updateQuestionType = (headerIndex: number, questionIndex: number, type:string) => {
        let survery = parsedSurvery.structure;
        survery[headerIndex].questions[questionIndex].type = type;
        setParsedSurvey({ ...parsedSurvery, structure: survery});
    }

    const triggerAnalysis = () => {
        setModalOpen(false);
        onSubmit(parsedSurvery)
    }

    const FileUploader = () => {
        const [fileName, setFileName] = useState('');

        const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const file = event.target.files?.[0];
            if (file) {
                setFileName(file.name);
                manageFile(file);
            }
        };

        const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
            event.preventDefault();
            const file = event.dataTransfer.files[0];
            if (file) {
                setFileName(file.name);
                manageFile(file);
            }
        };

        const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
            event.preventDefault();
        };

        return (
            <>
                {!analysing && (
                    <div
                        className="upload_container"
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                    >
                        <div className="text_upload">
                            <img src="/img/csv.svg" alt="CSV icon" className="csv-icon" />
                            <h6>Upload a CSV file</h6>
                            <p>{fileName || 'file.csv/xls/xlsx'}</p>
                        </div>
                        <input
                            type="file"
                            id="fileInput"
                            accept=".csv,.xlsx,.xls"
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                        />
                        <button
                            className='button_upload'
                            onClick={() => document.getElementById('fileInput')?.click()}
                        >
                            Choose file
                        </button>
                    </div>
                )}
            </>
        );
    };

    return (
        <div>
            <FileUploader />
            {analysing && (
                <>
                    <LinearProgress color="secondary" />
                    <br />
                    Analysing question structure..
                    <br />
                    <br />
                    <br />
                </>
            )}
            {sheetData.length > 0 && !analysing && (
                <QuestionDataBuilder
                    responseCount={sheetData.length}
                    parseData={parseData}
                    analysedSurvey={analysedSurvey}
                />
            )}
            <TypeMapper
                survey={parsedSurvery}
                modalOpen={modalOpen}
                updateQuestionType={updateQuestionType}
                triggerAnalysis={triggerAnalysis}
                setModalOpen={setModalOpen}
            />
        </div>
    );
}
