import { useAuth0 } from "@auth0/auth0-react";
import { Container, Toolbar, Button, Grid, Paper, LinearProgress, Box, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import FathomClient from "api/fathomapi";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ProgramParticipants from "./dataupload/dataupload";
import { Survey } from "./dataupload/types";

export default function ReportCreate() {

  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const [report, setReport] = useState({
    title: "",
    deliveryMode: "online",
    evaluationPoint: "PreProgram",
    dataSource: "MSForms"
  });

  const { programId, reportId } = useParams();
  const [loading, setLoading] = useState(false);
  const [creating, setCreating] = useState(false);

  const saveReportData = async (surveyData:Survey) => {
    setCreating(true);
    const apiClient = new FathomClient(await getAccessTokenSilently());
    if (reportId !== undefined) {
      const { data } = await apiClient.put(
        `{clientId}/programs/` + programId + '/reports/' + reportId,
        {
          structure: surveyData.structure,
          title: report.title,
          deliveryMode: report.deliveryMode,
          evaluationPoint: report.evaluationPoint,
          responseCount: surveyData.responseCount
        }
      );

      setCreating(false);

      let rId = data.id;
      navigate("/programs/" + programId + "/reports/" + rId + `/view`);
    } else {
      const data = await apiClient.post(
        `{clientId}/programs/` + programId + '/reports/',
        {
          structure: surveyData.structure,
          title: report.title,
          deliveryMode: report.deliveryMode,
          evaluationPoint: report.evaluationPoint,
          responseCount: surveyData.responseCount
        }
      ).then(res => {
        return res.data
      }).catch((e:any) => {
        console.log(e);
      });
      if(data !== undefined){
        setCreating(false);
        let rId = data.id;
        navigate("/programs/" + programId + "/reports/" + rId + `/view`);
      }
    }

  }

  useEffect(() => {
    if (reportId !== "" && reportId !== undefined) {
      setLoading(true);
      const fetchData = async () => {
        const apiClient = new FathomClient(await getAccessTokenSilently());
        const { data } = await apiClient.get(`{clientId}/programs/` + programId + `/reports/` + reportId);
        if (data.outcomes == null) {
          data.outcomes = [];
        }
        if (data.evaluationQuestions == null) {
          data.evaluationQuestions = [];
        }
        if (data.postAssessmentQuestions == null) {
          data.postAssessmentQuestions = [];
        }
        setReport(data);
        setLoading(false);
      };

      fetchData().catch(console.error);
    }

  }, [programId, getAccessTokenSilently, reportId]);


  const deleteProgram = async () => {
    if (confirm("This will delete the report, are you sure?")) {
      const apiClient = new FathomClient(await getAccessTokenSilently());
      if (reportId !== "") {
        await apiClient.delete(
          "{clientId}/programs/" + programId + "/reports/" + reportId
        );

        navigate("/programs/" + programId + "/reports")
      }
    }
  };

  return (
    <div>
      <Container>
      <Container>

        <br />
          <Button onClick={() => navigate("/programs/" + programId + "/reports")}>Reports</Button>
          {reportId && <Button variant="outlined" onClick={() => deleteProgram()}  color="error" sx={{float: 'right', marginBottom: '-30px'}}> Delete </Button> }
          <br />
          <br />
        <Grid container spacing={4} >
          <Grid item xs={12}>
            {" "}
            {!loading && (
              <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                <br />
                <Container>
                  <InputLabel>
                    Report title
                  </InputLabel>
                  <TextField id="outlined-basic" fullWidth variant="outlined"
                    value={report.title}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setReport({ ...report, title: event.target.value });
                    }} />
                  <br /><br />
                  <Grid container spacing={4}>
                    <Grid sm={6} item>
                      <InputLabel id="delivery-mode-select-label">Location</InputLabel>
                      <Select
                        sx={{ width: "100%" }}
                        labelId="delivery-mode-select-label"
                        id="delivery-mode-select"
                        value={report.deliveryMode}
                        label="Delivery Mode"
                        onChange={(mode: SelectChangeEvent) => {
                          setReport({ ...report, deliveryMode: mode.target.value });
                        }}
                      >
                        <MenuItem value={"online"}>Online</MenuItem>
                        <MenuItem value={"offline"}>In-person</MenuItem>
                      </Select>
                    </Grid>
                    <Grid sm={6} item>
                      <InputLabel id="evaluation-point-select-label">Evaluation Point</InputLabel>
                      <Select
                        sx={{ width: "100%" }}
                        labelId="evaluation-point-select-label"
                        id="evaluation-point-select"
                        value={report.evaluationPoint}
                        label="Evaluation Point"
                        onChange={(point: SelectChangeEvent) => {
                          setReport({ ...report, evaluationPoint: point.target.value });
                        }}
                      >
                        <MenuItem value={"PreProgram"}>Pre-program</MenuItem>
                        <MenuItem value={"EndOfProgram"}>End of program</MenuItem>
                        <MenuItem value={"FollowUp"}>Follow-up</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                  <hr />
                  <ProgramParticipants value={report} setValue={setReport} saveReportData={saveReportData} />
                
                </Container>

              </Paper>
            )}
            {loading && (
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "20px",
                }}
              >
                <LinearProgress color="secondary" />
                <br />
                <br />
                <br />
              </Paper>
            )}
          </Grid>
        </Grid>
        <br />
                <br />
                <br />
                <br />
      </Container>
      </Container>
    </div>
  );

}