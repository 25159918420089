import React, { useRef } from "react";
import { Container, Grid, Paper, Typography } from "@mui/material";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import PollIcon from "@mui/icons-material/Poll";
import ReactMarkdown from "react-markdown";
import "./reportQuestions.css";
export default function ViewQuestionsReport({ report }: any) {
  const [currentQuestion, setCurrentQuestion] = React.useState(
    report.questionAnalysis.questionsSummary[0],
  );
  const env = process.env.REACT_APP_ENV;

  const breakdownRef = useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if (breakdownRef.current) {
      breakdownRef.current.scrollTop = 0;
    }
  }, [currentQuestion]);
  const [currentActiveQuestion, setActiveCurrentQuestion] = React.useState(0);
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const getQuestions = () => {
    return report.questionAnalysis.questionsSummary.map((x: any, i: number) => (
      <Paper
        className={currentActiveQuestion == i ? "activeQuestion" : ""}
        sx={{
          p: 2,
          display: "flex",
          cursor: "pointer",
          flexDirection: "column",
          marginBottom: "20px",
        }}
        key={i}
        onClick={() => {
          setCurrentQuestion(x);
          setActiveCurrentQuestion(i);

          if (breakdownRef.current) {
            breakdownRef.current.scrollTop = 0;
          }
        }}
      >
        <Container>
          <Grid container spacing={2}>
            <Grid sm={12} item>
              <Typography variant="subtitle2">
                <QuestionAnswerIcon
                  sx={{
                    color: "#45C27E",
                    width: "17px",
                    height: "17px",
                    marginRight: "5px",
                    marginTop: "-1px",
                  }}
                />{" "}
                Q{i + 1}
              </Typography>
              <Typography variant="subtitle1">{x.questionHeader}</Typography>
            </Grid>
          </Grid>
        </Container>
      </Paper>
    ));
  };

  const getMetrics = (rating: any, green: boolean = true) => {
    if (env == "Prod" || rating.metricScore == undefined) {
      return (<></>)
    }
    return (<Grid container spacing={4} sx={{ marginBottom: "15px", marginTop: "10px" }}>
      <Grid xs={3} >
        <HighchartsReact
          highcharts={Highcharts}
          options={getMetricCircleOptions(rating.metricScore.level1, "Reaction", green)}
          ref={chartComponentRef}
          containerProps={{ style: { height: "100%" } }}
        />
      </Grid>
      <Grid xs={3} >
        <HighchartsReact
          highcharts={Highcharts}
          options={getMetricCircleOptions(rating.metricScore.level2, "Learning", green)}
          ref={chartComponentRef}
        />
      </Grid>
      <Grid xs={3}>
        <HighchartsReact
          highcharts={Highcharts}
          options={getMetricCircleOptions(rating.metricScore.level3, "Behavior", green)}
          ref={chartComponentRef}
        />
      </Grid>
      <Grid xs={3} >
        <HighchartsReact
          highcharts={Highcharts}
          options={getMetricCircleOptions(rating.metricScore.level4, "Results", green)}
          ref={chartComponentRef}
        />
      </Grid>
    </Grid>)
  }

  return (
    <Container>
      <br />
      <br />
      <Grid container spacing={4}>
        <Grid item lg={8} md={8} sm={8} />
        <Grid item xs={4} sx={{ marginBottom: "-80px", marginTop: "-50px", height: "200px" }}>
          {getMetrics(report, false)}
        </Grid>
      </Grid>
      <br />
      <Paper id="reportTitle" className="reportTile">
        <Typography variant="subtitle1">
          <PollIcon
            sx={{ color: "#6327E6", marginRight: "10px", marginTop: "-2px" }}
          />
          Summary
        </Typography>
        <hr />
        <ReactMarkdown>{report.questionAnalysis.overallSummary}</ReactMarkdown>
      </Paper>
      <br />
      <br />
      <Grid container spacing={4}>
        <br />
        <br />
        <Grid item xs={4} >
          Questions
        </Grid>
        <Grid item xs={8} >
          Breakdown
        </Grid>
        <Grid item xs={4} className="scroll-container questions-container">
          {getQuestions()}
        </Grid>
        <Grid item xs={8} className="scroll-container breakdown-container" ref={breakdownRef}>
          <div className="breakdown-content">
            {currentQuestion.multiResponses &&
              currentQuestion.multiResponses?.map((multi: any) => {
                let rate = 100;
                let unres = 0;
                if (multi.multiCount.hasOwnProperty("")) {
                  unres = multi.multiCount[""];
                  let res = report.participants;

                  rate = Math.round(100 - (unres / res) * 100);
                }

                let options: Highcharts.Options = getMultiChartOptions(multi);
                return (
                  <>
                    <Grid container spacing={4}>
                      <Grid item lg={6} md={6} sm={6} />
                      <Grid item xs={6}>
                        {getMetrics(multi)}
                      </Grid>
                    </Grid>
                    <Paper className="reportTile" key={multi.title}>
                      <Typography sx={{ opacity: "0.8", textTransform: "none" }}>
                        {rate}% response rate{" "}
                        {unres > 0 && (
                          <span>({unres} participants didn&apos;t respond)</span>
                        )}
                      </Typography>
                      <br />
                      <br />
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        ref={chartComponentRef}
                      />
                    </Paper>
                  </>
                );
              })}
            {currentQuestion.opinionResponses &&
              currentQuestion.opinionResponses?.map((multi: any) => {
                let rate = 100;
                let unres = 0;
                if (multi.multiCount.hasOwnProperty("")) {
                  unres = multi.multiCount[""];
                  let res = report.participants;

                  rate = Math.round(100 - (unres / res) * 100);
                }

                let options: Highcharts.Options = getOpinionChartOptions(multi, report.participants - unres);

                return (
                  <>
                    <Grid container spacing={4}>
                      <Grid item lg={6} md={6} sm={6} />
                      <Grid item xs={6} >
                        {getMetrics(multi)}
                      </Grid>
                    </Grid>
                    <Paper className="reportTile" key={multi.title}>
                      <Typography sx={{ opacity: "0.8", textTransform: "none" }}>
                        {rate}% response rate{" "}
                        {unres > 0 && (
                          <span>({unres} participants didn&apos;t respond)</span>
                        )}
                      </Typography>
                      <br />
                      <br />
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        ref={chartComponentRef}
                      />
                    </Paper>
                  </>
                );
              })}
            {currentQuestion.ratingResponses &&
              currentQuestion.ratingResponses?.map((rating: any) => {
                let options: Highcharts.Options = getRatingChartOptions(rating);
                let rate = 100;
                let unres = 0;
                if (rating.ratingCount.hasOwnProperty("")) {
                  unres = rating.ratingCount[""];
                  let res = report.participants;

                  rate = Math.round(100 - (unres / res) * 100);
                }
                return (
                  <>
                    <Grid container spacing={4}>
                      <Grid item lg={6} md={6} sm={6} />
                      <Grid item xs={6} >
                        {getMetrics(rating)}
                      </Grid>
                    </Grid>
                    <Paper
                      className="reportTile"
                      key={rating.title}
                    >
                      <Typography sx={{ opacity: "0.8", textTransform: "none" }}>
                        {rate}% response rate{" "}
                        {unres > 0 && (
                          <span>({unres} participants didn&apos;t respond)</span>
                        )}
                      </Typography>
                      <br />
                      <br />
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        ref={chartComponentRef}
                      />
                    </Paper>
                  </>
                );
              })}
            {currentQuestion.openTextResponses.length > 1 && (
              <>

                <Grid container spacing={4}>
                  <Grid item lg={6} md={6} sm={6} />
                  <Grid item xs={6} >
                    {getMetrics(currentQuestion.openTextResponses)}
                  </Grid>
                </Grid>
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "20px",
                  }}
                >
                  <Typography variant="h6">
                    {currentQuestion.questionHeader}
                  </Typography>{" "}
                  <hr />
                  <Typography variant="subtitle2">
                    {currentQuestion.summary}
                  </Typography>
                  <br />
                </Paper></>
            )}
            {currentQuestion.openTextResponses.map((response: any) => {
              return (<>
                <Paper
                  sx={{ marginBottom: "25px", borderShadow: "10px" }}
                  key={response.questionId}
                >
                  <Container>
                        <br />
                        <Typography variant="h6">{response.title}</Typography>{" "}
                        <br />
                        <Typography variant="subtitle1">
                          {currentQuestion.summary}
                        </Typography>{" "} 
                        <br />
                      
                      </Container>
                </Paper>
                {response.headers.map((header: any) => {
                  return (
                    <Paper
                      sx={{ marginBottom: "25px", borderShadow: "10px" }}
                      key={header.heading}
                    >
                      <Container>
                        <br />
                        <Typography variant="h6">{header.heading}</Typography>{" "}
                        <br />
                        <Typography variant="subtitle1">
                          {header.description}
                        </Typography>{" "}
                        <br />
                        {header.quote.map((quote: any) => {
                          return (
                            <>
                              {" "}
                              <Typography variant="subtitle1">
                                <i>&quot;{quote}&quot;</i>
                              </Typography>{" "}
                              <br />
                            </>
                          );
                        })}
                      </Container>
                    </Paper>
                  )
                }
                )}</>)
            })}
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}

function getOpinionChartOptions(multi: any, participants: any): Highcharts.Options {
  return {
    title: {
      text: multi.title,
    },
    yAxis: {
      reversedStacks: false,
      title: {
        text: null
      },
      labels: { enabled: false }
    },
    xAxis: {
      categories: [""],
      reversedStacks: false,

    },
    credits: {
      enabled: false
    },
    plotOptions: {
      series: {
        stacking: "normal",
        dataLabels: {
          enabled: true,
          format: '{point.y:,.0f} %'
        },

      },
    },
    series: GetOrderedOpinionKeys(multi.multiCount).map((key: string) => {
      return {
        name: key,
        type: "bar",
        data: [Math.round(multi.multiCount[key] / participants * 100 * 100) / 100],
      };
    }),
  };
}

function GetOrderedOpinionKeys(multi: Record<string, number>) {
  const order = [
    "not confident at all",
    "not confident",
    "a little confident",
    "somewhat confident",
    "confident",
    "quite confident",
    "very confident",
    "strongly disagree",
    "disagree",
    "neutral",
    "neither agree nor disagree",
    "agree",
    "strongly agree",
    "never",
    "rarely",
    "sometimes",
    "often",
    "always",
    "very dissatisfied",
    "dissatisfied",
    "neutral",
    "neither satisfied nor dissatisfied",
    "satisfied",
    "very satisfied",
  ];
  return Object.keys(multi).filter((z) => z !== "").sort(function (a, b) {
    return order.indexOf(a.toLowerCase()) - order.indexOf(b.toLowerCase());
  });
}

function getMultiChartOptions(multi: any): Highcharts.Options {
  return {
    title: {
      text: multi.title,
    },
    yAxis: {
      reversedStacks: false,
      title: {
        text: null
      },
      labels: { enabled: false }

    },
    credits: {
      enabled: false
    },
    xAxis: {
      categories: [""],
      reversedStacks: false,
    },
    plotOptions: {
      series: {
        stacking: "normal",
        dataLabels: {
          enabled: true,
        },
      },
    },
    series: GetOrderedKeys(multi.multiCount).map((key: string) => {
      return {
        name: key,
        type: "bar",
        data: [multi.multiCount[key]],
      };
    }),
  };
}

function GetOrderedKeys(multi: Record<string, number>) {
  return Object.keys(multi)
    .filter((z) => z !== "")
    .sort(function (a, b) {
      return parseInt(a) - parseInt(b);
    });
}

function getRatingChartOptions(rating: any): Highcharts.Options {
  return {
    title: {
      text: rating.title,
    },
    yAxis: {
      reversedStacks: false,
      title: {
        text: null
      },
      labels: { enabled: false }
    },
    xAxis: {
      categories: [""],
      reversedStacks: false,
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
        },
      },
    },
    series: GetOrderedKeys(rating.ratingCount).map((key: string) => {
      return {
        name: key,
        type: "column",
        data: [rating.ratingCount[key]],
      };
    }),
  };
}

function getMetricCircleOptions(score: any, title: string, green: boolean = true): Highcharts.Options {
  return {
    chart: {
      renderTo: 'container',
      type: 'pie',
      backgroundColor: 'rgba(0,0,0,0.0)',
      spacingTop: 0,
      spacingRight: 0,
      spacingBottom: 0,
      spacingLeft: 0,
      plotBorderWidth: 0,
      margin: [0, 0, 0, 0],
      height: 100,
    },
    title: {
      verticalAlign: 'middle',
      floating: true,
      text: score + '%<br>' + title,
      style: {
        fontSize: '15px'
      }
    },
    xAxis: {
      gridLineColor: 'transparent',
      gridLineWidth: 0,
      lineColor: 'transparent',
      lineWidth: 0,
      labels: {
        enabled: false
      },
      tickWidth: 0,
      minPadding: 0,
      maxPadding: 0
    },
    yAxis: {
      title: {
        text: null
      }
    },
    plotOptions: {
      pie: {
        innerSize: '100%'
      },
      series: {
        states: {
          hover: {
            enabled: false
          },
          inactive: {
            opacity: 1
          }
        }
      }
    },
    series: [{
      name: 'Score',
      data: [{
        y: score,
        name: "Online",
        color: green ? 'rgb(69, 194, 126)' : 'rgb(99, 39, 230)',
      },
      {
        y: 100 - score,
        name: "Online",
        color: 'rgba(0,0,0,0.1)'
      }
      ],
      showInLegend: false,
      dataLabels: {
        enabled: false
      },
      type: "pie",
      size: '125%',
      innerSize: '85%',
    }],
    credits: {
      enabled: false
    },
    tooltip: {
      enabled: false
    }
  };
}