import { useAuth0 } from "@auth0/auth0-react";
import { Container, Grid, Button, Paper, LinearProgress, Typography, Pagination, Stack, TextField } from "@mui/material";
import FathomClient from "api/fathomapi";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Moment from "moment";
import BuildIcon from '@mui/icons-material/Build';
import BarChartIcon from '@mui/icons-material/BarChart';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LoopIcon from '@mui/icons-material/Loop';
import ErrorIcon from '@mui/icons-material/Error';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { PagedResult } from "app/types";
import debounce from 'lodash/debounce';

export default function ReportsList() {

    const { getAccessTokenSilently } = useAuth0();
    const [reports, setReports] = useState({
        data: [],
        paging: {
          length: 0,
          pageSize: 0,
          page: 0,
          total: 0
        }
      } as PagedResult<any>);
    const [loading, setLoading] = useState(true);
    const { programId } = useParams();
    const navigate = useNavigate();
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);

    
    const loadReports= async (searchTerm: string, page: number) =>
        {
            const apiClient = new FathomClient(await getAccessTokenSilently());
            const { data } = await apiClient.get(`{clientId}/programs/` + programId + `/reports?search=`+searchTerm+"&page="+page);
            setReports(data);
            setLoading(false);
      }


    useEffect(() => {
        // declare the data fetching function
        const fetchData = async () => {
            loadReports("", 1)

        };
        setLoading(true);
          fetchData()
            .catch(console.error);
    }, []);

    const edit = (programId: string, reportId: string) => {
        navigate("/programs/" + programId + "/reports/" + reportId + "/create");
    };

    const viewReports = (programId: string, reportId: string) => {
        navigate("/programs/" + programId + "/reports/" + reportId + "/view");
    };

    const createNew = (programId?: string) => {
        navigate("/programs/" + programId + "/reports/create");
    };

    const viewPrograms = () => {
        navigate("/programs/");
    };
    const syncing = (report: any) => {
        return report.status !== "Complete";
    }

    const isError = (status: string) => {
        return (status || "").toLowerCase().indexOf("error") > -1;
    }

    const IsAdmin = () => {
        return localStorage.getItem("x-fathom-currentRole") == "Admin"
      }
    
    const handleChangePage = (_: React.ChangeEvent<unknown>, newPage: number) => {
        if(newPage != page) {
        setPage(page);
        loadReports(search, newPage)
        }
    };

    const handleSearch = debounce((searchTerm: string, pageNo:number) => {
        loadReports(searchTerm, pageNo)
    }, 300);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setSearch(newValue)
        handleChangePage({} as ChangeEvent<unknown>, 1)
        handleSearch(newValue, 1);
    };

    const getReports = () => {
        return reports.data.map((x: any) => (
            <Paper className="programOverview" sx={{ p: 2, display: "flex", flexDirection: "column", marginBottom: "20px" }} key={x.id}>
                <Container>
                    <Grid container spacing={2} alignItems="center">
                        <Grid sm={6} item>
                            <Typography
                                sx={{
                                    marginTop: "10px",
                                }}
                                variant="h4"
                            >
                                {x.title}
                            </Typography>
                            <Typography variant="subtitle2">Participants: {x.participants}<span className="bulletSpacer">•</span>Evaluation Point: {x.evaluationPoint}<span className="bulletSpacer">•</span>{Moment(x.startDateUtc).format("Do MMM yyyy")}</Typography>

                        </Grid>
                        <Grid sm={3} item>
                            {x.completeDateUtc !== null && <span className="statusPositive"><CheckCircleIcon sx={{ fontSize: 16, color: "#0E8345", marginRight: "8px", }}></CheckCircleIcon>{Moment(x.completeDateUtc).format("Do MMMM YYYY")}</span>}
                            {syncing(x) && !isError(x.status) && <span className="statusProgress"><LoopIcon sx={{ fontSize: 16, color: "#0e4a83", marginRight: "8px", }}></LoopIcon>&nbsp;&nbsp;Running &nbsp;&nbsp;</span>}
                            {syncing(x) && isError(x.status) && <span className="statusError"><ErrorIcon sx={{ fontSize: 16, color: "#830e0e", marginRight: "8px", }}></ErrorIcon>&nbsp;&nbsp;&nbsp;&nbsp;Error&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>}
                        </Grid>
                        <Grid sm={3} item textAlign="right">
                            <Button variant="outlined" hidden={!IsAdmin()} disableElevation size="small" sx={{
                                margin: "0 8px",
                            }} onClick={() => edit(programId || "", x.id)}>
                                <BuildIcon fontSize="inherit" />&nbsp; Edit
                            </Button>
                            <Button variant="contained" disableElevation size="small" sx={{
                                marginTop: "0 8px",
                            }} onClick={() => viewReports(programId || "", x.id)}>
                                <BarChartIcon fontSize="inherit" />  &nbsp; View Report
                            </Button>
                        </Grid>
                        <br />
                        <br />
                        <br />
                        <br />
                    </Grid>
                </Container>
            </Paper>
        ));
    };

    return (
        <div>
            <Container>
                <br />

                <Grid item xs={12}>
                    <Container>
                        <Button onClick={() => viewPrograms()} sx={{ opacity: "0.6", textTransform: 'none', ml: "-13px" }}><ArrowBackIcon sx={{ width: "16px", height: "16px" }} /> &nbsp; Back to programs</Button>
                        <br />
                        {reports.paging.length == 0 ?
                            <Button sx={{ ml: "-10px" }} >Reports</Button> :
                            <Button sx={{ ml: "-10px" }}>{(reports.data as any[])[0].programName}</Button>
                        }
                        <Button variant="outlined" hidden={!IsAdmin()} onClick={() => createNew(programId)} sx={{ float: 'right', marginBottom: '-30px' }}>
                            New Report
                        </Button>
                        <br />
                        <br />
                        {loading && reports.paging.length == 0 &&
                            <Paper sx={{ p: 2, display: "flex", flexDirection: "column", marginBottom: "20px" }} >
                                <LinearProgress color="secondary" />
                                <br />
                                <br />
                                <br />
                            </Paper>
                        }
                        {!loading &&
                            <>
                            <Stack spacing={2} direction="row" justifyContent="left" sx={{ marginBottom: '-20px'}}>
                            <TextField
                                label="Search"
                                variant="standard"
                                value={search}
                                onChange={handleSearchChange}
                            />
                            </Stack>
                            <Stack spacing={2} direction="row" justifyContent="right" sx={{ marginBottom: '20px'}}>
                            <Pagination
                                count={Math.ceil(reports.paging.total / reports.paging.pageSize)}
                                page={reports.paging.page}
                                onChange={handleChangePage}
                                color="primary"
                                variant="outlined"
                                showFirstButton showLastButton
                            />
                            </Stack>
                            </>
                            }
                        {reports.paging.length == 0 && !loading && (
                            <Paper>
                                {" "}
                                <Container>
                                    <br />
                                    <Typography variant="h6">
                                        No reports found
                                    </Typography>
                                    <br />
                                </Container>
                            </Paper>
                        )}
                        {getReports()}
                        <br />
                    </Container>
                </Grid>
            </Container>

        </div>
    );
}