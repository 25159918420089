import ProtectedRoute from "../auth/protected-route";
import { Route, Routes } from "react-router-dom";
import { CallbackPage } from "../auth/callback";
import User from "./user/user";
import Programs from "./programs/programs";
import CreateProgram from "./programs/create/create";
import ViewReport from "./reports/view/view";
import ReportsList from "./reports/reports";
import ReportCreate from "./reports/create/create";
import UsersList from "./users/users";
import ClientList from "./clientSelect/clientSelect";
import AcceptInvite from "./invite/invite";
import LoginPage from "./login/login";
import NotFound from "./NotFound"
const Routing = () => {
  return (
    <Routes >
      <Route
        path="/"
        element={<ProtectedRoute component={User} />}
      />
            <Route
        path="/login"
        element={<ProtectedRoute component={LoginPage} />}
      />
      <Route
        path="/programs"
        element={<ProtectedRoute component={Programs} />}
      />
      <Route
        path="/programs/create/:id?/:step?"
        element={<ProtectedRoute component={CreateProgram} />}
      />
      <Route
        path="/programs/:programId/reports/:reportId/view/:reportType?"
        element={<ProtectedRoute component={ViewReport} />}
      />
       <Route
        path="/programs/:programId/reports/:reportId?/create"
        element={<ProtectedRoute component={ReportCreate} />}
      />
      <Route
        path="/programs/:programId/reports"
        element={<ProtectedRoute component={ReportsList} />}
      />
      <Route
        path="/users"
        element={<ProtectedRoute component={UsersList} />}
      />
      <Route
        path="/select"
        element={<ProtectedRoute component={ClientList} />}
      />
      <Route
        path="/invite"
        element={<ProtectedRoute component={AcceptInvite} />}
      />
      <Route
        path="/callback"
        element={<ProtectedRoute component={CallbackPage} />} />
      <Route
          path="*"
          element={<NotFound />}
        />
    </Routes>
  )
}

export default Routing;
